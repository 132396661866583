import React from "react";
import styled from "styled-components";
import { BenefitsTile, TileNavigation } from "../components/tile-navigation";
import { Headline2 } from "../components/typography";
import InfoSection from "../sections/jobs/info-section";
import OffersList from "../sections/jobs/offers-list";
import Layout from "../components/layout";
import { graphql, PageProps } from "gatsby";
import { JobOfferListingInterface } from "../graphql/jobs";
import { ApplyJobModal } from "../components/apply-job";
import { JobOfferDataInterface } from "../components/apply-job/apply-job-modal";
import { JobsPageContentInterface } from "../graphql/jobs-page";
import { PimcoreImage } from "../components/pimcore-image";
import { theme } from "../styles/styles";
import { useSimpleScrollspy } from "../hooks/use-simple-scrollspy";

interface JobsDataInterface {
  pimcore: {
    content: JobsPageContentInterface;
    jobOffers: JobOfferListingInterface;
  };
}

const categories: Array<{ title: string; key: string }> = [
  { title: "eCommerce", key: "ecommerce" },
  { title: "Data", key: "data-and-analytics" },
  { title: "IT", key: "it" },
  { title: "Customer Engagement", key: "customer-engagement-and-performance-marketing" },
  { title: "People & Engagement", key: "people-and-engagement" },
  { title: "Operations", key: "operational-excellence-and-information-management" },
  { title: "Marketing", key: "marketing" },
  { title: "Other", key: "other" },
];

const Jobs: React.FC<PageProps<JobsDataInterface>> = ({ data }) => {
  const [currentOffer, setCurrentOffer] = React.useState<JobOfferDataInterface | undefined>();
  const categoriesRef = React.useRef<{ [key: string]: HTMLDivElement | null }>({});
  const content = data.pimcore.content;
  useSimpleScrollspy("jobsSection");

  const onCategoryClick = (key: string, e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    categoriesRef.current[key]?.scrollIntoView({ behavior: "smooth" });
  };

  const onApplyClick = (offerId: string, jobTitle: string) => {
    setCurrentOffer({ offerId, jobTitle });
  };

  const getJobOffers = (category: string) =>
    data.pimcore.jobOffers.edges.filter((edge) => edge.node.category === category).map((edge) => edge.node);
  const countJobOffers = (category: string) => getJobOffers(category).length;

  return (
    <Layout
      navCurrentItem="jobs"
      title={content.metaTitle}
      description={content.metaDescription}
      canonical={content.metaCanonical}
      openGraph={{ title: content.metaOgTitle, description: content.metaOgTitle, image: content.metaOgImage }}
      schemaOrg={content.metaSchema}
    >
      <Header>
        <PimcoreImage
          image={content.heroBackground}
          mobileImage={content.heroBackgroundMobile}
          loading="eager"
          withAspectRatio
        />
      </Header>
      <Main>
        <HeadlineWrapper>
          <Headline2 as="h1" align="text-center">
            <strong>{data.pimcore.jobOffers.totalCount}</strong> open job vacancies
          </Headline2>
        </HeadlineWrapper>
        <StickyWrapper>
          <CategoriesWrapper>
            <CategoriesBox>
              {categories.map((category) => (
                <CategoryLink
                  key={category.key}
                  href={`#${category.key}`}
                  data-nav-item
                  onClick={onCategoryClick.bind(null, category.key)}
                >
                  <CategoryTitle>{category.title}</CategoryTitle>
                  <CategoryCounter>
                    <span> ({countJobOffers(category.key)}) </span>
                  </CategoryCounter>
                </CategoryLink>
              ))}
            </CategoriesBox>
          </CategoriesWrapper>
          <OffersWrapper>
            {categories.map((category) => (
              <OffersList
                key={category.key}
                header={category.title}
                jobs={getJobOffers(category.key)}
                onApply={onApplyClick}
                ref={(ref) => (categoriesRef.current[category.key] = ref)}
              />
            ))}
          </OffersWrapper>
        </StickyWrapper>
        <InfoSection content={content} />
        <TileNavigation top={"work"} bottom={"team"}>
          <BenefitsTile />
        </TileNavigation>
      </Main>
      <ApplyJobModal offerData={currentOffer} onClose={() => setCurrentOffer(undefined)} />
    </Layout>
  );
};

export const query = graphql`
  query {
    pimcore {
      ...jobsPageData
      jobOffers: getJobOfferListing(published: true) {
        edges {
          node {
            slug
            title
            location
            endDate
            category
            jobOfferId
            color
          }
        }
        totalCount
      }
    }
  }
`;

export default Jobs;

const Header = styled.div.attrs({ className: "absolute left-0 top-0 w-full h-screen" })``;

const Main = styled.main.attrs({ className: "relative pt-72" })``;

const HeadlineWrapper = styled.div.attrs({ className: "pb-40 tablet:pb-0 w-4/5 mx-auto tablet:w-full" })``;

const CategoriesWrapper = styled.div.attrs({
  className: " mx-5 tablet:mx-24 tablet:sticky tablet:top-8 tablet:z-40 tablet:mb-8",
})``;

const CategoriesBox = styled.div.attrs({
  className:
    "flex flex-wrap gap-x-4 tablet:gap-x-0 rounded-lg bg-gray18/70 px-7 py-5 backdrop-blur-md tablet:justify-between tablet:rounded-full",
})`
  @supports (-moz-appearance: none) {
    background-color: rgba(50, 50, 50, 0.97);
  }
`;

const CategoryLink = styled.a.attrs({
  className: "block text-white cursor-pointer hover:text-yellow hover:text-underline tablet:pr-0 ",
})`
  &.activeSection {
    color: ${theme.colors.yellow};
  }
`;

const CategoryTitle = styled.span.attrs({ className: "font-bold inline" })``;

const CategoryCounter = styled.span.attrs({ className: "font-normal inline text-gray5" })``;

const OffersWrapper = styled.div.attrs({ className: "pt-8 tablet:pt-64" })``;
const StickyWrapper = styled.div.attrs({ className: "" })``;
